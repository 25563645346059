<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Reports</div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between mb-5">
      <form class="conFormSubmit conSearchForm mt-5">
        <v-row>
          <v-col cols="12" md="4">
            <label class="eachLabel">Year</label>
            <v-select
              v-model="name"
              outlined
              :items="years"
              item-text="name"
              item-value="name"
              placeholder="Year"
              hide-details="true"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              clearable
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <label class="eachLabel">Month</label>
            <v-select
              outlined
              v-model="month_id"
              :items="months"
              item-text="name"
              item-value="month_id"
              placeholder="Month"
              hide-details="true"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              clearable
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-col>
          <v-col md="4" cols="12" class="d-flex align-end">
            <v-btn @click="getListData()" class="btnAddPrimary"> Search </v-btn>
          </v-col>
        </v-row>
      </form>
    </div>
    <v-row>
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="5"
              hide-default-footer
            >
            </v-data-table>
          </div>
        </section>
        <div
          class="conOfPagination pr-5 pt-5"
          v-if="pagination.total > pagination.per_page"
        >
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

export default {
  data: () => ({
    name: "",
    month_id: "",

    years: [
      {
        year_id: 1,
        name: 2020,
      },
      {
        year_id: 2,
        name: 2021,
      },
      {
        year_id: 3,
        name: 2022,
      },
      {
        year_id: 4,
        name: 2023,
      },
    ],
    months: [
      {
        month_id: 1,
        name: "January",
      },
      {
        month_id: 2,
        name: "February",
      },
      {
        month_id: 3,
        name: "March",
      },
      {
        month_id: 4,
        name: "April",
      },
      {
        month_id: 5,
        name: "May",
      },
      {
        month_id: 6,
        name: "June",
      },
      {
        month_id: 7,
        name: "July",
      },
      {
        month_id: 8,
        name: "August",
      },
      {
        month_id: 9,
        name: "September",
      },
      {
        month_id: 10,
        name: "October",
      },
      {
        month_id: 11,
        name: "November",
      },
      {
        month_id: 12,
        name: "December",
      },
    ],

    pagination: {
      current_page: 1,
    },
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    secDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    menu1: false,
    menu2: false,

    headers: [
      { text: "Country", value: "country_name" },
      { text: "Total Hour", value: "total_hours" },
      { text: "Date", value: "date" },
    ],

    listData: [],
  }),
  computed: {},
  methods: {
    goToPage() {
      this.getListData();
      window.scrollTo(0, 0);
    },
    getListData() {
      this.listData = [];

      apiServices
        .get(`reports?month=${this.month_id}&year=${this.name}`)
        .then((res) => {
          console.log("ListData", this.listData);

          if (res) {
            // console.log("ListData", this.listData);

            this.isLoadingSearch = false;
            this.listData = res.data;
            this.pagination = res.meta;
            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
  },
  created() {
    this.getListData();
  },
  watch: {},
};
</script>
<style lang="scss" scoped></style>
